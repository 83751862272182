exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cidade-ciclavel-js": () => import("./../../../src/pages/cidade-ciclavel.js" /* webpackChunkName: "component---src-pages-cidade-ciclavel-js" */),
  "component---src-pages-consultoria-tecnologia-js": () => import("./../../../src/pages/consultoria-tecnologia.js" /* webpackChunkName: "component---src-pages-consultoria-tecnologia-js" */),
  "component---src-pages-gestao-de-bicicletarios-js": () => import("./../../../src/pages/gestao-de-bicicletarios.js" /* webpackChunkName: "component---src-pages-gestao-de-bicicletarios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

